<template>
  <div class="page-content">
      <UserAvatar/>
  </div>
</template>
<script>
import UserAvatar from '../../components/UserAvatar'
export default {
  components: {
    UserAvatar,
  },
}
</script>